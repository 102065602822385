import { useEffectOnce } from 'react-use';
import { proxy, useSnapshot } from 'valtio';

import { assertExhaustive, type CodeCanvasBlock } from '@lp-lib/game';
import { type Logger } from '@lp-lib/logger-base/src/LoggerBase';

import { markSnapshottable } from '../../../../utils/valtio';
import { RefreshIcon } from '../../../icons/RefreshIcon';
import { DialoguePlayerControl } from '../../../VoiceOver/Dialogue/DialoguePlayer';
import { CommonButton } from '../../design/Button';
import { EditableTextDisplay } from '../../design/EditableDisplay';
import {
  type BlockDependencies,
  type IBlockCtrl,
  type PlaygroundPlaybackProtocol,
} from '../../types';
import { CodePlayer } from './CodePlayer';

type GameState = {
  status: 'init' | 'present' | 'play' | 'complete';
};

export class CodeCanvasBlockControlAPI implements IBlockCtrl {
  private _state = markSnapshottable(
    proxy<GameState>({
      status: 'init',
    })
  );

  private delegate: Nullable<PlaygroundPlaybackProtocol>;
  private logger: Logger;

  private _dialoguePlayer: DialoguePlayerControl;

  constructor(block: CodeCanvasBlock, private deps: BlockDependencies) {
    this.logger = deps.getLogger('code-canvas-block');

    this._dialoguePlayer = new DialoguePlayerControl(
      block.fields.dialogue,
      deps,
      new Map(),
      this.logger
    );
  }

  get state() {
    return this._state;
  }

  get dialoguePlayer() {
    return this._dialoguePlayer;
  }

  getLVOCtrl() {
    return this.deps.getLVOCtrl();
  }

  async preload() {
    const preloads: Promise<void>[] = [];
    preloads.push(this._dialoguePlayer.preload());
    await Promise.all(preloads);
  }

  async initialize(preloaded: Promise<void>) {
    await preloaded;
  }

  async present() {
    this.state.status = 'present';
  }

  async play() {
    this.state.status = 'play';
    const info = this.dialoguePlayer.play();
    await info.started;
    await info.ended;
    this.state.status = 'complete';
  }

  async replay() {
    this.state.status = 'init';
  }

  async end() {
    this.deps.sfxControl.play('instructionHoverReadyButton');
    await this.delegate?.blockDidEnd();
  }

  async destroy() {
    return;
  }

  setDelegate(delegate: PlaygroundPlaybackProtocol) {
    this.delegate = delegate;
  }
}

function Init(props: { ctrl: CodeCanvasBlockControlAPI }) {
  useEffectOnce(() => {
    props.ctrl.present();
  });

  return null;
}

function Present(props: {
  block: CodeCanvasBlock;
  ctrl: CodeCanvasBlockControlAPI;
  status: Exclude<GameState['status'], 'init'>;
}) {
  const { status } = props;

  return (
    <div className='absolute inset-0'>
      <CodePlayer
        block={props.block}
        ctrl={props.ctrl}
        onLoad={() => props.ctrl.play()}
      />

      {status === 'complete' && (
        <div className='absolute bottom-3 left-3 right-3 flex justify-center'>
          <div
            className={`w-full max-w-240 flex items-center justify-center gap-2`}
          >
            <CommonButton
              variant='gray'
              onClick={() => {
                props.ctrl.replay();
              }}
              styles={{ size: 'h-11.5' }}
              style={{
                aspectRatio: '1/1',
              }}
            >
              <RefreshIcon className='w-4 h-4 fill-current' />
            </CommonButton>
            <CommonButton variant='brand' onClick={() => props.ctrl.end()}>
              <EditableTextDisplay value={'Continue'} />
            </CommonButton>
          </div>
        </div>
      )}
    </div>
  );
}

export function CodeCanvasBlockPlayground(props: {
  block: CodeCanvasBlock;
  ctrl: CodeCanvasBlockControlAPI;
}) {
  const { status } = useSnapshot(props.ctrl.state);

  switch (status) {
    case 'init':
      return <Init ctrl={props.ctrl} />;
    case 'present':
    case 'play':
    case 'complete':
      return <Present block={props.block} ctrl={props.ctrl} status={status} />;
    default:
      assertExhaustive(status);
      return null;
  }
}
